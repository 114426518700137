@import '../../../theme/base/variables';

/**
 *  Input styles
 */
.input {
  position: relative;
  display: inline-block;

  input:not([type='submit']) {
    font-family: $font-primary;
    vertical-align: middle;
    appearance: none;
    border: 1px solid $color-gray-border;
    padding: 1.75rem 1rem 0.5rem;
    border-radius: $base-border-radius;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.27;

    &:hover {
      border-color: $color-gray-text;

      &::placeholder {
        color: rgba($color-black-blue, 0.6);
      }
    }

    &:focus {
      border-color: $theme-color-primary;

      &::placeholder {
        color: transparent;
      }
    }

    &::placeholder {
      color: rgba($color-black-blue, 0.4);
    }

    &[disabled] {
      background-color: #f5f5f5;

      &,
      &:hover {
        border: 0;
      }

      &::placeholder {
        color: rgba($color-black-blue, 0.4);
      }

      & + span {
        color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  span {
    position: absolute;
    left: 1rem;
    top: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.28;
    color: rgba(0, 0, 0, 0.4);
  }

  &.error {
    input:not([type='submit']) {
      border-color: #e21523;
    }

    span {
      color: #e21523;
    }
  }
}

// Turn Off Number Input Spinners
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
