.section-banner {
  text-align: center;

  //@media (min-width: $desktop) {
  //  padding: 3rem 0;
  //}

  .container {
    display: flex;

    @media (min-width: $tablet) {
      align-items: center;
    }
  }

  &__content {
    width: 100%;
    position: relative;

    @media (min-width: $tablet) {
      padding-right: 45%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;
    }

    &.img_CTA {
      @media (min-width: $tablet) {
        min-height: 270px;
      }

      @media (min-width: $desktop) {
        min-height: 426px;
      }
    }
  }

  &__img {
    @media (min-width: $tablet) {
      position: absolute;
      width: 45%;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      justify-content: center;
    }

    picture {
      @media (min-width: $tablet) {
        margin: auto;
      }
    }
  }

  &__heading {
    text-align: left;
    margin: 0 auto;
    line-height: 1.2;

    @media (min-width: $tablet) {
      max-width: 100%;
      margin: 0;
      font-size: 2rem;
    }

    @media (min-width: $desktop) {
      font-size: 3.25rem;
    }

    &--center {
      text-align: center;

      @media (min-width: $tablet) {
        text-align: left;
      }
    }

    span {
      color: $theme-color-primary;
      font-family: $font-secondary;
      font-size: 2.5rem;
      line-height: 1.1;
      font-weight: 400;
      letter-spacing: 0.2px;

      @media (min-width: $desktop) {
        font-size: 5.25rem;
        line-height: 0.9;
      }

      @media (min-width: $desktop) {
        font-size: 4.75rem;
        line-height: 0.9;
        white-space: nowrap;
      }
    }

    br {
      display: none;

      @media (min-width: $desktop) {
        display: block;
      }
    }
  }

  &__description {
    width: 100%;
    text-align: left;
    margin: 1rem 0 0;
    line-height: 1.5;

    @media (min-width: $tablet) {
      margin-top: 2rem;
    }

    p {
      color: #405262;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 420px;
    margin: 1rem auto 0;

    @media (min-width: $tablet) {
      max-width: 100%;
      margin: 3rem 0 0;
      justify-content: flex-start;
    }

    @media (min-width: $desktop) {
      margin-top: 2.5rem;
    }

    &--center {
      justify-content: center;
    }

    .btn {
      @media (min-width: $mobile) {
        padding-right: 2.5rem;
        padding-left: 2.5rem;
      }

      @media (min-width: $tablet) {
        margin-right: 1rem;
      }
    }
  }

  &--competent-writers {
    @media (min-width: $desktop) {
      .section-banner__img {
        top: -45px;
      }
    }
  }

  &--advantages {
    * {
      text-align: left;
    }

    @media (max-width: $tablet) {
      padding-bottom: 0;
    }

    .section-banner__content {
      @media (min-width: $tablet) {
        padding-right: 36%;
      }

      @media (max-width: $desktop) {
        padding-right: 0;
      }
    }

    .section-banner__img {
      @media (min-width: $tablet) {
        width: 36%;
        align-items: flex-end;
      }

      &--desktop {
        display: none;

        @media (min-width: $desktop) {
          display: flex;
        }

        picture {
          margin: auto 0 auto auto;
        }
      }
      &--mobile {
        width: 100%;
        margin-top: 30px;

        @media (min-width: $desktop) {
          display: none;
        }
      }
    }
  }

  &--competent-writers {
    @media (min-width: $desktop) {
      padding: 3rem 0;
    }
  }

  &--custom-papers {
    padding-left: 1rem;
    padding-right: 1rem;

    // fix priority
    @media (max-width: $desktop) {
      padding-top: 2.5rem !important;
    }

    @media (min-width: $desktop) {
      padding-bottom: 100px;
      padding-top: 0;
    }

    .section-banner__content.img_CTA {
      min-height: 292px;
    }

    .container {
      border-radius: $base-border-radius;
      background-color: $theme-color-primary;
      padding: 40px 32px;

      @media (min-width: $desktop) {
        padding: 50px 100px;
      }

      .section-banner__buttons {
        justify-content: center;
        margin-top: 1.5rem;
      }

      .section-banner__img {
        @media (min-width: $desktop) {
          top: calc(50% - 298px / 2);
        }
      }

      .section-banner__heading {
        color: white;
        margin-bottom: 1rem;

        @media (min-width: $desktop) {
          margin-bottom: 0;
        }

        span {
          color: white;
          display: block;
        }
      }
    }

    &__info {
      color: white;
      font-size: 14px;
      margin-top: 32px;
      margin-bottom: 0;

      @media (min-width: $desktop) {
        text-align: left;
        margin-top: 40px;
      }

      &__link {
        color: white;
        text-decoration: underline;

        &:active,
        &:focus,
        &:hover {
          color: rgba(white, 0.5);
          text-decoration: none;
        }
      }
    }
  }
}
