.section-seo {
  a {
    color: $theme-color-primary;

    &:hover {
      text-decoration: underline;
    }
  }

  .container {
    > .section-seo__block,
    > h3,
    > p {
      max-width: 800px;

      @media (min-width: $desktop) {
        margin: 1rem 0 1.5rem;
      }
    }

    > p:last-of-type {
      @media (min-width: $desktop) {
        margin: 1rem 0 0;
      }
    }
  }

  .section__heading {
    position: relative;
    line-height: 1.2;
    margin: 0 0 1rem;

    @media (min-width: $desktop) {
      max-width: 1000px;
      line-height: 1.1;
      margin: 0 0 1rem;
    }

    &:not(:first-child) {
      margin-top: 2.5rem;

      @media (min-width: $desktop) {
        margin-top: 2rem;
      }
    }

    &::before {
      content: '';
      width: 40px;
      height: 40px;
      position: absolute;
      top: -45px;
      left: 0;
      background: center center no-repeat;
      background-size: contain;

      @media (min-width: $tablet) {
        width: 80px;
        height: 80px;
        top: -4px;
        left: -100px;
      }
    }

    &--bubble1 {
      &::before {
        background-image: $ico_seo-title-bubble-1;
      }
    }

    &--bubble2 {
      &::before {
        background-image: $ico_seo-title-bubble-2;
      }
    }

    &--bubble3 {
      &::before {
        background-image: $ico_seo-title-bubble-3;

        @media (min-width: $tablet) {
          top: 0;
        }
      }
    }

    br {
      display: none;

      @media (min-width: $desktop) {
        display: block;
      }
    }
  }

  &.section-calculator {
    .section__description {
      font-size: 1rem;
    }
  }

  .seo-links-block {
    margin: 1.5rem 0;

    @media (min-width: $desktop) {
      margin: 2rem 0 2.5rem;
    }

    a {
      color: $color-black-blue;
      font-weight: 700;

      &:hover {
        text-decoration: none;
        color: $theme-color-primary;
      }
    }
  }
}

.seo-blocks {
  @media (min-width: $desktop) {
    margin: 1.5rem 0;
  }

  &--column {
    @media (min-width: $tablet) {
      display: grid;
      justify-items: stretch;
      column-gap: 1.5rem;
      row-gap: 1rem;
      width: 100%;
    }

    &-2 {
      @media (min-width: $tablet) {
        grid-template-columns: 1fr 1fr;
      }
    }

    &-3 {
      @media (min-width: $tablet) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}
