/*
 *  Fonts
 */
$font-primary: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
  Cantarell, 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
$font-secondary: 'Carosello Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;

/*
 *  Font size
 */
$base-font-size: 16px;
// heading
$h1-desktop-font-size: 52px;
$h1-mobile-font-size: 30px;
$h2-desktop-font-size: 52px;
$h2-mobile-font-size: 28px;
$h3-desktop-font-size: 24px;
$h3-mobile-font-size: 20px;

/*
 *  Line height
 */
$base-line-height: 1.6;
$h1-line-height: 1.2;
$h2-line-height: 1.2;
$h3-line-height: 1.35;

/*
 *  Font weight
 */
$base-font-weight: 400;

/*
 *  Colors
 */
$font-color: #1b1b1b;
$color-black-blue: #0d2436;
$theme-color-primary: #05ada3;
$color-gray-bg: #f2f6f9;
$color-gray-border: #b7c6cd;
$color-gray-icon: rgba($color-black-blue, 0.4);
$color-gray-text: #405262;

// link
$base-link-color: $theme-color-primary;
$base-link-hover-color: $theme-color-primary;

/*
 *  Responsive widths
 */
$desktop-large: 1280px;
$desktop-medium: 1140px;
$desktop: 1024px;
$tablet: 768px;
$mobile: 480px;
$mobile-small: 360px;

/*
 *  Blocks width
 */
$container-width: 1252px;
$section-padding-mobile: 1.5rem;
$section-padding-desktop: 1rem;

/*
 *  Border radius
 */
$base-border-radius: 8px;
$large-border-radius: 30px;

/*
 *  Transition
 */
$transition-primary: 0.25s;

$base-box-shadow: 0 15px 40px rgba(21, 37, 72, 0.08);
