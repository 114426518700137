.section-seotext {
  padding: 0;
  overflow: hidden;

  @media (min-width: $desktop) {
    padding: 0 0.5rem;
  }

  .container {
    padding: 2.5rem 1rem 2.5rem;
    position: relative;
    margin: 0 auto;
    max-width: calc(100% - 1rem);
    color: #b7c6cd;
    border-radius: $base-border-radius;
    background: $color-black-blue;

    @media (min-width: $desktop) {
      padding: 5rem 3rem;
      max-width: calc(#{$container-width} - 1rem);
    }

    @media (min-width: $desktop-large) {
      padding: 7.5rem 6.25rem 3.5rem;
      max-width: calc(#{$container-width} - 2rem);
    }
  }

  h2 {
    @extend .h3;

    color: $color-gray-bg;
    line-height: 1.3;
    margin-bottom: 1rem;
    max-width: 600px;

    @media (min-width: $desktop) {
      margin-bottom: 1.5rem;
      line-height: 1.42;
    }
  }

  p,
  li {
    color: $color-gray-border;
  }

  &__row {
    position: relative;
    z-index: 0;

    @media (min-width: $desktop) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 4rem;
    }

    &.load-bg:first-child::after {
      background: $img_seo-bg center bottom/contain no-repeat;
    }

    &:first-child {
      padding-bottom: 6.5rem;

      @media (min-width: $desktop) {
        padding-bottom: 14.375rem;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -1rem;
        width: calc(100% + 2rem);
        height: 100%;
        z-index: -1;

        @media (min-width: $desktop) {
          left: -3rem;
          width: calc(100% + 6rem);
        }

        @media (min-width: $desktop-large) {
          left: -6.25rem;
          width: calc(100% + 12.5rem);
        }
      }
    }

    &.load-bg:nth-of-type(2)::after {
      background: $img_seo-bg2 center/cover no-repeat;
    }

    &:nth-of-type(2) {
      padding-bottom: 13.4rem;

      @media (min-width: $desktop) {
        padding-bottom: 4rem;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 1rem;
        right: -2rem;
        width: 353px;
        height: 353px;
        z-index: -1;

        @media (min-width: $desktop) {
          width: 551px;
          height: 551px;
          bottom: -2rem;
        }
      }
    }
  }

  &__cell {
    @media (min-width: $desktop) {
      max-width: calc(50% - 1.5rem);
    }
  }
}
