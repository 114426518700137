*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

html,
body {
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100vh;
}

body {
  background-color: white;
  font-family: $font-primary;
  color: $font-color;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: $base-font-weight;
  word-wrap: break-word;
  min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

.illustration {
  user-select: none;
  pointer-events: none;
}

iframe {
  max-width: 100%;
}
