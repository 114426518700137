/**
 *  Main Footer
 */
@import '../../sections/section-socials';

.footer {
  background-color: #071826;

  a {
    transition: color 0.2s, opacity 0.2s;
    color: #5a7184;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.39;

    @media (min-width: $desktop) {
      line-height: 1.86;
    }

    &:hover,
    &:focus {
      color: white;
      text-decoration: none;
    }
  }

  &__label {
    font-size: 1.25rem;
    line-height: 1.42;
    color: rgba(white, 0.6);
    margin-bottom: 1rem;
    display: inline-block;
    width: 100%;

    @media (min-width: $desktop) {
      font-size: 1.5rem;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &--column-2 {
      > * {
        max-width: 100%;

        @media (min-width: $desktop) {
          max-width: calc(50% - 0.25rem);
        }
      }
    }

    &--column-4 {
      &:first-child {
        padding-bottom: 0.5rem;
        margin-bottom: 2rem;
        border-bottom: 2px solid rgba(#e5eaf4, 0.05);
      }

      > * {
        max-width: calc(50% - 0.25rem);

        @media (min-width: $desktop) {
          max-width: calc(25% - 0.25rem);
        }
      }
    }

    &--services {
      margin-bottom: 1.5rem;

      @media (min-width: $desktop) {
        margin-bottom: 3rem;
      }

      li {
        width: 100%;
      }
    }
  }

  &__cell {
    width: 100%;
    margin-bottom: 2rem;

    p {
      color: #5a7184;
    }

    p:last-child {
      margin-bottom: 0;
    }

    &.footer__cell--size-2 {
      max-width: 100%;

      //@media (min-width: $tablet) {
      //  max-width: calc(66.6666% - 0.25rem);
      //}

      @media (min-width: $desktop) {
        max-width: calc(50% - 0.25rem);
      }
    }

    &--terms {
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 100%;
          max-width: calc(50% - 0.25rem);
        }
      }
    }

    &--attention {
      @media (min-width: $desktop) {
        max-width: 360px;
        margin-right: auto;
        margin-bottom: 0;
      }
    }

    &--disclaimer {
      margin-bottom: 0.5rem;

      @media (min-width: $desktop) {
        max-width: 600px;
      }
    }
  }

  li {
    padding: 0;
    margin: 0 0 0.5rem;
    background: none;
  }

  &__langs__list {
    max-width: 120px;
    margin-bottom: 1.5rem;

    @include mqMin($tablet) {
      max-width: 100%;
    }

    a {
      width: 35px;
      height: 24px;
      opacity: 0.7;
      display: inline-block;
      margin: 0 0.75rem 0.75rem 0;

      @include mqMin($tablet) {
        margin: 0 0.25rem;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &__accept {
    img {
      max-height: 24px;

      @media (min-width: $desktop) {
        max-height: 35px;
      }
    }
  }

  &__copyright {
    @media (min-width: $desktop) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4rem;
    }

    p {
      color: #5a7184;

      @media (min-width: $desktop) {
        margin: 0 1.5rem 0 0;
      }
    }
  }

  &__dmca {
    display: block;
    margin-top: 2rem;

    @media (min-width: $desktop) {
      display: inline-block;
      margin-top: 0;
    }
  }
}
