/* stylelint-disable */

/* latin-ext */
@font-face {
  font-family: 'Caveat Brush';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Caveat Brush'), local('CaveatBrush-Regular'),
    url(https://fonts.gstatic.com/s/caveatbrush/v5/EYq0maZfwr9S9-ETZc3fKXt8UrOS43o.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Caveat Brush';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Caveat Brush'), local('CaveatBrush-Regular'),
    url(https://fonts.gstatic.com/s/caveatbrush/v5/EYq0maZfwr9S9-ETZc3fKXt8XLOS.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('DM Sans Regular'), local('DMSans-Regular'),
    url(https://fonts.gstatic.com/s/dmsans/v4/rP2Hp2ywxg089UriCZ2IHSeH.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('DM Sans Regular'), local('DMSans-Regular'),
    url(https://fonts.gstatic.com/s/dmsans/v4/rP2Hp2ywxg089UriCZOIHQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('DM Sans Medium'), local('DMSans-Medium'),
    url(https://fonts.gstatic.com/s/dmsans/v4/rP2Cp2ywxg089UriAWCrCBamC2QX.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('DM Sans Medium'), local('DMSans-Medium'),
    url(https://fonts.gstatic.com/s/dmsans/v4/rP2Cp2ywxg089UriAWCrCBimCw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('DM Sans Bold'), local('DMSans-Bold'),
    url(https://fonts.gstatic.com/s/dmsans/v4/rP2Cp2ywxg089UriASitCBamC2QX.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('DM Sans Bold'), local('DMSans-Bold'),
    url(https://fonts.gstatic.com/s/dmsans/v4/rP2Cp2ywxg089UriASitCBimCw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: 'Carosello Regular';
  src: url('./fonts/Carosello/Carosello-Regular.woff2') format('woff2'),
    url('./fonts/Carosello/Carosello-Regular.woff') format('woff'),
    url('./fonts/Carosello/Carosello-Regular.svg') format('svg'),
    url('./fonts/Carosello/Carosello-Regular.ttf') format('truetype'),
    url('./fonts/Carosello/Carosello-Regular.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* stylelint-enable */
