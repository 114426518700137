@import '../../../theme/base/variables';

/**
 *  Main Header
 */

.header {
  position: relative;
  top: 0;
  background-color: white;
  z-index: 21;
  height: 80px;
  display: flex;
  align-items: center;
  transition: height 0.1s;

  @media (min-width: $desktop-medium) {
    height: auto;
  }

  .UserBlock {
    display: flex;
  }

  &--outside-wrap {
    min-height: 80px;
  }

  &--sticky {
    position: fixed;
    width: 100%;
    box-shadow: $base-box-shadow;
    height: 68px;

    @media (min-width: $desktop-medium) {
      height: auto;
    }

    .header__menu-wrapper {
      margin-top: 68px;

      @media (min-width: $desktop-medium) {
        margin-top: 0;
      }
    }

    .header__menu__item > * {
      @media (min-width: $desktop-medium) {
        padding-top: 31px;
        padding-bottom: 31px;
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    display: inline-block;
    z-index: 1;
    margin-right: auto;

    img {
      max-width: 190px;

      @media (min-width: $mobile) {
        max-width: 226px;
      }

      @media (min-width: $desktop-large) {
        max-width: none;
      }
    }
  }

  .btn {
    box-shadow: 0 6px 12px rgba(26, 141, 205, 0.2);
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: 400;
    font-size: 0.875rem;

    &--order-desktop {
      display: none;

      @media (min-width: $desktop-medium) {
        display: block;
        margin-left: 2rem;
      }
    }
  }

  &__manage-orders {
    margin: 0 12px 0 0;
    text-indent: -150px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px rgba($color-gray-border, 0.3) inset;
    background: $ico_manage-orders center center no-repeat;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    @media (min-width: $desktop-medium) {
      box-shadow: none;
      border-radius: 0;
      color: $theme-color-primary;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 0.875rem;
      margin: 0;
      overflow: visible;
      width: auto;
      height: auto;
      text-indent: unset;
      padding-left: 1.5rem;
      background-position: left center;
    }
  }

  &__toggle-menu {
    width: 24px;
    height: 20px;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    order: 1;

    @media (min-width: $desktop-medium) {
      display: none;
    }

    span {
      position: relative;

      &,
      &::after,
      &::before {
        height: 3px;
        width: 100%;
        background-color: $theme-color-primary;
      }

      &::after,
      &::before {
        content: '';
        position: absolute;
        transition: transform 0.2s;
      }

      &::after {
        top: 8px;
      }

      &::before {
        top: -8px;
      }
    }

    &--active {
      span {
        background-color: transparent;

        &::after,
        &::before {
          top: 0;
        }

        &::after {
          transform: rotateZ(45deg);
        }

        &::before {
          transform: rotateZ(-45deg);
        }
      }
    }
  }

  &__menu {
    @media (min-width: $desktop-medium) {
      display: flex;
    }

    &-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0 1.5rem 9rem;
      height: 100vh;
      margin-top: 5rem;
      overflow: auto;
      opacity: 0;
      visibility: hidden;
      background-color: white;

      @media (min-width: $desktop-medium) {
        position: static;
        padding: 0;
        opacity: 1;
        visibility: visible;
        display: flex;
        width: auto;
        height: auto;
        margin: 0 0 0 auto;
        align-items: center;
        overflow: visible;
      }

      &--open {
        opacity: 1;
        visibility: visible;
      }

      .btn {
        display: inline-block;
        margin-top: 20px;

        @media (min-width: $desktop-medium) {
          display: none;
        }
      }
    }

    &__item {
      background: none;
      padding: 0;
      margin: 0;

      @media (min-width: $desktop-medium) {
        margin: 0 2rem 0 0;
        font-size: 0.875rem;
        position: relative;
      }

      a {
        color: $color-black-blue;
        border-top: 1px solid rgba($color-gray-text, 0.2);

        @media (min-width: $desktop-medium) {
          border: 0;
        }

        &:focus,
        &:hover {
          color: $theme-color-primary;
          text-decoration: none;
        }
      }

      > * {
        font-weight: 700;
        padding: 19px 0;
        display: inline-block;
        width: 100%;
        line-height: 1.3;

        @media (min-width: $desktop-medium) {
          width: auto;
          padding: 43px 0;
          transition: padding-top 0.1s, padding-bottom 0.1s;
        }
      }

      span {
        border-top: 0;
      }
    }
  }
}
