@import '../../../theme/base/variables';

/**
 *  Select styles
 */
.select {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  background-color: white;

  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 1rem;
    border-width: 9px 6px 0;
    border-style: solid;
    border-color: black transparent transparent;
    border-radius: 1px;
    z-index: 1;

    @media (min-width: $desktop) {
      border-width: 9px 7px 0;
    }
  }

  &::before {
    content: '';
    width: 80px;
    height: calc(100% - 2px);
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 1px;
    background: linear-gradient(-90deg, rgba(255, 255, 255, 1) 43%, rgba(255, 255, 255, 0) 58%);
    border-radius: $base-border-radius;
    z-index: 1;
  }

  select {
    font-family: $font-primary;
    vertical-align: middle;
    cursor: pointer;
    appearance: none;
    padding: 1.75rem 1rem 0.65rem 1rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.3;
    color: black;
    width: 100%;
    white-space: nowrap;
    border-radius: $base-border-radius;
    border: 1px solid $color-gray-border;
    height: 60px;
    background-color: transparent;

    @media (min-width: $desktop) {
      font-size: 1.125rem;
    }

    &:hover {
      border-color: #405262;
    }

    &:focus,
    &:active {
      border-color: $theme-color-primary;
    }

    &::-ms-expand {
      display: none;
    }

    &[disabled] {
      background-color: #f5f5f5;

      &,
      &:hover {
        border: 0;
      }

      &::placeholder {
        color: rgba($color-black-blue, 0.4);
      }

      & + span {
        color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  span {
    position: absolute;
    left: 1rem;
    top: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.28;
    color: rgba(0, 0, 0, 0.4);
  }
}
