/*
 * Media Queries
 *
 * @include mqMin(1025px|mobile|tablet|desktop) { ... }
 * @include mqMax(1025px|mobile|tablet|desktop) { ... }
*/
@mixin mqMin($media) {
  @if (type-of($media) != string) {
    @media screen and (min-width: $media) {
      @content;
    }
  } @else {
    @if $media == mobile {
      @media screen and (min-width: $mobile) {
        @content;
      }
    } @else if $media == tablet {
      @media screen and (min-width: $tablet) {
        @content;
      }
    } @else if $media == desktop {
      @media screen and (min-width: $desktop) {
        @content;
      }
    } @else {
      @content;
    }
  }
}

@mixin mqMax($media) {
  @if (type-of($media) != string) {
    @media screen and (max-width: $media) {
      @content;
    }
  } @else {
    @if $media == mobile {
      @media screen and (max-width: $mobile) {
        @content;
      }
    } @else if $media == tablet {
      @media screen and (max-width: $tablet) {
        @content;
      }
    } @else if $media == desktop {
      @media screen and (max-width: $desktop) {
        @content;
      }
    } @else {
      @content;
    }
  }
}

/*
 * Device Pixel Ratio
 *
 * @include maxPixelRatio(4|retina|no-retina) { ... }
*/
@mixin maxPixelRatio($media) {
  @if (type-of($media) != string) {
    @media only screen and (-webkit-max-device-pixel-ratio: $media) {
      @content;
    }
  } @else {
    @if $media == retina {
      @media only screen and (-webkit-max-device-pixel-ratio: 2) {
        @content;
      }
    } @else if $media == no-retina {
      @media only screen and (-webkit-max-device-pixel-ratio: 1.25) {
        @content;
      }
    } @else {
      @content;
    }
  }
}

@mixin minPixelRatio($media) {
  @if (type-of($media) != string) {
    @media only screen and (-webkit-min-device-pixel-ratio: $media) {
      @content;
    }
  } @else {
    @if $media == retina {
      @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        @content;
      }
    } @else if $media == no-retina {
      @media only screen and (-webkit-min-device-pixel-ratio: 1.25) {
        @content;
      }
    } @else {
      @content;
    }
  }
}
