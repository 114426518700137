.section-attention {
  .attention-block {
    max-width: 1012px;
    margin: 0 auto;
    border-radius: $base-border-radius;
    background-color: #faefeb;
    padding: 18px 16px;
    display: grid;
    text-align: center;
    justify-items: center;
    align-items: center;

    @media (min-width: $desktop) {
      padding: 60px 78px;
      grid-template-columns: 90px 1fr;
      column-gap: 22px;
      text-align: left;
    }

    p {
      margin-bottom: 0;
      font-size: 16px;

      @media (min-width: $desktop) {
        font-size: 20px;
      }
    }

    img {
      @media (max-width: $desktop) {
        width: 48px;
        height: 48px;
        margin-bottom: 1rem;
      }
    }
  }
}
