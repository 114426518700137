.section-accordion {
  @media (min-width: $tablet) {
    padding-bottom: 0;
  }
}

.section-platinum-emails {
  padding-bottom: 0;
}

#platinumGatherEmails {
  @media (max-width: $tablet) {
    scroll-margin-top: 50px;
    scroll-snap-margin-top: 50px;
  }
}

.footer {
  @media (min-width: $desktop) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &__row {
    &--bottom {
      display: grid;
      padding-top: 2rem;

      @media (min-width: $desktop) {
        grid-template-columns: 360px 2fr;
        grid-column-gap: 55px;
        margin-top: 70px;
        padding-top: 0;
      }

      .footer__cell {
        @media (min-width: $desktop) {
          margin-bottom: 0;
        }
      }
    }

    &--info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: 2px solid rgba(#e5eaf4, 0.05);

      @media (min-width: $desktop) {
        grid-column-gap: 55px;
        grid-template-columns: 360px 235px 144px 1fr;
      }
    }
  }

  &__cell {
    @media (min-width: $desktop) {
      margin-bottom: 60px;
    }

    &--attention {
      max-width: unset;
    }

    &--disclaimer {
      max-width: unset;
    }

    .section-socials__links {
      justify-content: flex-start;

      &__item {
        opacity: 1;

        &:hover {
          opacity: 0.5;
        }

        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    &:nth-of-type(1) {
      @media (max-width: $desktop) {
        grid-column: 1/3;
        grid-row: 1/2;
      }
    }

    &:nth-of-type(2) {
      @media (max-width: $desktop) {
        grid-column: 1/2;
        grid-row: 2/3;
      }
    }

    &:nth-of-type(3) {
      @media (max-width: $desktop) {
        grid-column: 2/3;
        grid-row: 2/3;
      }
    }

    &:nth-of-type(4) {
      @media (max-width: $desktop) {
        grid-column: 1/3;
        grid-row: 3/4;
      }
    }
  }

  &__copyright {
    justify-content: flex-start;

    @media (min-width: $desktop) {
      margin-top: 40px;
    }
  }

  &__badges {
    display: flex;
    align-items: center;
    padding-top: 2rem;
    border-top: 2px solid rgba(#e5eaf4, 0.05);

    @media (min-width: $desktop) {
      padding-top: 0;
      border-top: none;
    }

    a:first-of-type {
      margin-right: 30px;
    }
  }

  .section-socials__apps__item {
    display: block;
    margin-top: 1rem;

    @media (min-width: $desktop) {
      margin-top: 2rem;
    }
  }
}

