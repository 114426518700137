@import '../../../theme/base/variables';

/**
 *  Heading styles
 */
h1,
.h1 {
  color: $color-black-blue;
  font-size: $h1-mobile-font-size;
  line-height: $h1-line-height;
  font-weight: 700;

  @media (min-width: $desktop) {
    font-size: $h1-desktop-font-size;
  }
}

h2,
.h2 {
  color: $color-black-blue;
  font-size: $h2-mobile-font-size;
  line-height: $h2-line-height;
  font-weight: 700;

  @media (min-width: $desktop) {
    font-size: $h2-desktop-font-size;
  }
}

h3,
.h3 {
  color: $color-black-blue;
  font-size: $h3-mobile-font-size;
  line-height: $h3-line-height;
  font-weight: 700;

  @media (min-width: $desktop) {
    font-size: $h3-desktop-font-size;
  }
}
