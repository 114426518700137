.section-features {
  padding: 2.5rem 0;

  .list {
    margin: 0 auto !important;
  }
}

.features {
  @media (min-width: $tablet) {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    margin-top: 1rem;
    padding: 0 0 0 72px;
    position: relative;
    background-repeat: no-repeat;


    @media (min-width: $desktop) {
      padding-left: 92px;
    }

    &__title {
      margin: 1.25rem 0 0.5rem;
      display: inline-block;
    }

    &__text {
      margin-bottom: 0;
    }
  }

  &--column-2 {
    .features__item {
      @media (min-width: $tablet) {
        max-width: calc(50% - 1rem);
      }
    }
  }
}
