.load-bg.ico_plagiarism-free {
  background-position: 0 top;
  background-image: $ico_plagiarism-free;
  background-size: 55px 54px;

  @media (min-width: $desktop) {
    background-size: 72px 79px;
  }
}

.load-bg.ico_superwriters {
  background-image: $ico_superwriters;
  background-size: 64px 62px;

  @media (min-width: $desktop) {
    background-size: 89px 86px;
  }
}

.load-bg.ico_freerevisions {
  background-image: $ico_freerevisions;
  background-size: 59px 60px;

  @media (min-width: $desktop) {
    background-size: 82px 80px;
  }
}

.load-bg.ico_flexible-pricing {
  background-image: $ico_flexible-pricing;
  background-size: 54px 53px;

  @media (min-width: $desktop) {
    background-size: 75px 76px;
  }
}

.load-bg.ico_flexibility {
  background-image: $ico_flexibility;
  background-size: 55px 55px;

  @media (min-width: $desktop) {
    background-size: 80px 80px;
  }
}

.load-bg.ico_short-deadlines {
  background-size: 59px 56px;
  background-image: $ico_short-deadlines;

  @media (min-width: $desktop) {
    background-size: 83px 78px;
  }
}

.load-bg.ico_custom-formatting {
  background-size: 52px 55px;
  background-image: $ico_custom-formatting;

  @media (min-width: $desktop) {
    background-size: 72px 77px;
  }
}

.load-bg.ico_disciplines {
  background-image: $ico_disciplines;
  background-size: 52px 57px;

  @media (min-width: $desktop) {
    background-size: 72px 80px;
  }
}

.load-bg.ico_easy-order-tracking {
  background-size: 53px 57px;
  background-image: $ico_easy-order-tracking;

  @media (min-width: $desktop) {
    background-size: 72px 79px;
  }
}

.load-bg.ico_free-features {
  background-size: 61px 57px;
  background-image: $ico_free-features;

  @media (min-width: $desktop) {
    background-size: 77px 81px;
  }
}

.load-bg.ico_quality-money-back {
  background-image: $ico_quality-money-back;
}

.load-bg.ico_quality {
  background-image: $ico_quality;
  background-size: 58px 58px;

  @media (min-width: $desktop) {
    background-size: 72px 76px;
  }
}

.load-bg.ico_deadlines {
  background-image: $ico_deadlines;
}

.load-bg.ico_discipline {
  background-image: $ico_discipline;
  background-size: 52px auto;

  @media (min-width: $desktop) {
    background-size: 72px auto;
  }
}

.load-bg.ico_moneyback {
  background-image: $ico_moneyback;
  background-size: 52px 55px;

  @media (min-width: $desktop) {
    background-size: 74px 76px;
  }
}

.load-bg.ico_security {
  background-image: $ico_security;
}

.load-bg.ico_narrow {
  background-image: $ico_narrow;
}

.load-bg.ico_complex {
  background-image: $ico_complex;
}

.load-bg.ico_testimonials {
  background-image: $ico_testimonials;
}

.load-bg.ico_samples {
  background-image: $ico_samples;
}

.load-bg.ico_tools {
  background-image: $ico_tools;
}

.load-bg.ico_expert {
  background-image: $ico_expert;
  background-size: 55px 56px;

  @media (min-width: $desktop) {
    background-size: 72px 81px;
  }
}

.load-bg.ico_secure {
  background-image: $ico_secure;
  background-size: 52px 57px;

  @media (min-width: $desktop) {
    background-size: 72px 77px;
  }
}

.load-bg.ico_revisions_refunds {
  background-size: 52px 56px;
  background-image: $ico_revisions_refunds;

  @media (min-width: $desktop) {
    background-position: 0 4px;
    background-size: 72px 78px;
  }
}

.load-bg.ico_confidentiality {
  background-size: 52px 55px;
  background-image: $ico_confidentiality;

  @media (min-width: $desktop) {
    background-position: 0 4px;
    background-size: 72px 77px;
  }
}

.load-bg.ico_fast-delivery {
  background-size: 52px 60px;
  background-image: $ico_fast-delivery;

  @media (min-width: $desktop) {
    background-size: 73px 82px;
  }
}

.load-bg.ico_secrets {
  background-size: 52px 57px;
  background-image: $ico_secrets;

  @media (min-width: $desktop) {
    background-size: 71px 78px;
  }
}

.load-bg.ico_phone-contacts {
  background-image: $ico_phone-contacts;
}

.load-bg.ico_location {
  background-image: $ico_location;
}

.load-bg.ico_chat {
  background-image: $ico_chat;
}

.load-bg.ico_email {
  background-image: $ico_email;
}

.load-bg.ico_flexible_prices {
  background-image: $ico_flexible_prices;
  background-size: 58px 58px;

  @media (min-width: $desktop) {
    background-size: 80px 80px;
  }
}

.load-bg.ico_writer {
  background-image: $ico_writer;
}

.load-bg.ico_writer-award {
  background-image: $ico_writer-award;

  @media (min-width: $desktop) {
    background-size: 80px 81px;
  }
}

.load-bg.ico_paper-star {
  background-image: $ico_paper-star;
  background-size: 60px 60px;

  @media (min-width: $desktop) {
    background-size: 80px 80px;
  }
}

.load-bg.ico_assistance {
  background-image: $ico_assistance;
  background-size: 60px 64px;

  @media (min-width: $desktop) {
    background-size: 80px 84px;
  }
}
