@import '../../../theme/base/variables';

/**
 *  Loader styles
 */
$spinner-size: 40px;
$spinner-thickness: 2px;
$spinner-min-offset: 20px;
$spinner-color: $theme-color-primary;

.Loader {
  background-color: transparent;
  width: 100%;
  min-height: $spinner-size;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  &--center-mode {
    height: 100%;
    align-items: center;
  }

  &--top-mode {
    display: block;
    padding-top: $spinner-min-offset;
  }

  &--static {
    position: relative;
    min-height: $spinner-size + 20;
  }

  span,
  &__inner {
    display: block;
    width: $spinner-size;
    height: $spinner-size;
    border-radius: 50%;
    border-width: $spinner-thickness;
    border-style: solid;
    border-color: rgba($spinner-color, 0.1) rgba($spinner-color, 0.1) rgba($spinner-color, 0.6)
      rgba($spinner-color, 0.6);
    margin: 0 auto;
    transform: translateZ(0);
    animation: spinner-animation 0.35s infinite linear;
    position: relative;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin: 0;
    }
  }
}

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
