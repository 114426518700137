@keyframes faceAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.section-top--default {
  padding: 0;
  overflow: hidden;

  @media (min-width: $tablet) {
    padding: 3.125rem 0 1rem;
    overflow-x: clip;
    overflow-y: visible;
  }

  @media (min-width: $desktop) {
    overflow: visible;
  }

  @media (min-width: $desktop-large) {
    overflow-x: clip;
    overflow-y: visible;
  }

  .container {
    position: relative;
  }

  .section-top {
    &__people-top {
      width: 100%;
      max-width: 480px;
      height: 362px;
      position: relative;
      margin: -3.5rem auto -3.5rem;

      $imagePositions: 1% 36.7%, 16.6% 20.4%, 19.8% 51.3%, 38.6% 74%, 38.6% 42%, 44.8% 55%, 56% 33%,
        63% 66%, 76.8% 15.5%, 80% 40.8%;

      @each $position in $imagePositions {
        $i: index($imagePositions, $position);

        .face-#{$i} {
          left: #{nth($position, 1)};
          bottom: #{nth($position, 2)};
        }
      }

      @media (min-width: $tablet) {
        transform: rotateZ(-90deg) translateY(-255px);
      }

      @media (min-width: $desktop-large) {
        max-width: 505px;
        height: 445px;
        margin-left: 160px !important;
        margin-top: 100px !important;
        opacity: 0.8;

        $imagePositions: 8px 121px, 76px 55px, 98px 215px, 187px 386px, 179px 141px, 217px 225px,
          246px 91px, 303px 309px, 396px 0px, 362px 151px;

        @each $position in $imagePositions {
          $i: index($imagePositions, $position);

          .face-#{$i} {
            animation-delay: #{$i/10}s;
            left: #{nth($position, 1)};
            bottom: #{nth($position, 2)};
          }
        }
      }

      svg {
        position: absolute;
        bottom: 60%;
        left: 85.75%;

        @media (min-width: $desktop-large) {
          position: absolute;
          bottom: -108%;
          left: 94.75%;
          width: 40px;
          height: 42px;
        }
      }
    }

    &__people-bottom {
      width: 100%;
      max-width: 480px;
      height: 312px;
      position: relative;
      margin: -3.5rem auto 0;

      $imagePositions: 0% 49%, 8% 30%, 30% 18%, 28% 32%, 40% 35.5%, 47.6% 6.4%, 60% 19.8%, 76.7% 0%,
        69% 45%, 84% 22%;

      @each $position in $imagePositions {
        $i: index($imagePositions, $position);

        .face-#{$i + 10} {
          left: #{nth($position, 1)};
          bottom: #{nth($position, 2)};
        }
      }

      @media (min-width: $tablet) {
        transform: rotateZ(-90deg) translateY(255px);
      }

      @media (min-width: $desktop-large) {
        max-width: 484px;
        height: 435px;
        margin-right: 167px !important;
        margin-top: 75px !important;

        $imagePositions: 30px 271px, 0px 172px, 101px 133px, 139px 183px, 193px 232px, 142px 47px,
          236px 92px, 269px 0px, 324px 276px, 344px 183px;

        @each $position in $imagePositions {
          $i: index($imagePositions, $position);

          .face-#{$i + 10} {
            animation-delay: #{$i/10}s;
            left: #{nth($position, 1)};
            bottom: #{nth($position, 2)};
          }
        }
      }

      svg:nth-of-type(1) {
        position: absolute;
        bottom: 4%;
        left: 5.5%;

        @media (min-width: $desktop-large) {
          position: absolute;
          bottom: 198%;
          left: -31.5%;
          transform: rotateZ(90deg);
        }
      }

      svg:nth-of-type(2) {
        position: absolute;
        bottom: 0;
        left: 22%;

        @media (min-width: $desktop-large) {
          position: absolute;
          bottom: 188%;
          left: -34.5%;
        }
      }
    }

    &__people-top,
    &__people-bottom {
      @media (min-width: $tablet) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
      }

      &.animate {
        > * {
          @media (min-width: $desktop-large) {
            animation-name: faceAnimation;
            animation-duration: 1.2s;
            animation-fill-mode: forwards;
          }
        }
      }

      > * {
        position: absolute;

        @media (min-width: $desktop-large) {
          opacity: 0;
        }

        img {
          max-width: none;

          @media (min-width: $tablet) {
            transform: rotateZ(90deg);
          }
        }
      }
    }

    &__content {
      position: relative;
      text-align: center;
      margin: 0 auto;
      height: 458px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 434px;

      @media (min-width: $desktop-large) {
        height: 664px;
        max-width: 555px;
      }

      &::before {
        content: '';
        z-index: -1;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 606px;
        height: 606px;
        border-radius: 50%;
        flex-wrap: wrap;
        background: linear-gradient(107.51deg, #10a8a6 9.42%, #b9e6e6 91.45%);
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        padding: 100px 70px;

        @media (min-width: $desktop-large) {
          width: 664px;
          height: 664px;
        }
      }

      &__title {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 700;
        letter-spacing: -0.2px;
        max-width: 240px;

        @media (min-width: $desktop-large) {
          margin: 4rem 0 -0.5rem;
          max-width: unset;
        }

        br {
          display: none;

          @media (min-width: $desktop-large) {
            display: inline-block;
          }
        }
      }

      &__subtitle {
        background: $img_covered-text center no-repeat;
        background-size: contain;
        position: relative;
        width: 272px;
        height: 90px;
        display: block;

        @media (min-width: $desktop-large) {
          font-size: 8rem;
          line-height: 1;
          width: 530px;
          height: 106px;
          margin-top: 16px;
          margin-bottom: 0.75rem;
        }

        span {
          display: inline-block;
          width: 100%;
          height: 100%;
          overflow: hidden;
          text-indent: 100%;
          white-space: nowrap;
        }
      }

      &__description {
        display: inline;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
      }

      .btn {
        margin-top: 1.5rem;
        z-index: 1;

        @media (min-width: $desktop-large) {
          margin-top: 2rem;
        }
      }
    }
  }
}

// fix for introducingPlatinum banner
.section-top--default .section-top__people-bottom .face-11 img {
  @media (min-width: $desktop-large) {
    opacity: 0.4;
  }
}
