@import '../../../theme/base/variables';

/**
 *  Button styles
 */
.btn {
  font-family: $font-primary;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  appearance: none;
  border-radius: $large-border-radius;
  padding: 14px 40px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  border: 0;
  display: inline-block;
  background-color: $theme-color-primary;
  transition: background-color 0.2s, box-shadow 0.2s;
  color: white;
  box-shadow: 0 6px 12px rgba(5, 173, 163, 0.16);

  &:hover,
  &:focus {
    color: white;
    background-color: #02c4ac;
    box-shadow: 0 6px 12px rgba(26, 141, 205, 0.2);
    text-decoration: none;
  }

  &:active {
    background-color: #06a49a;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    background-color: #f5f5f5;
    color: rgba(#405262, 0.6);
    cursor: default;
    box-shadow: none;
    text-decoration: none;
  }

  &--black-blue {
    background-color: $color-black-blue;
    box-shadow: 0 15px 40px rgba(21, 37, 72, 0.16);

    &:hover,
    &:focus {
      background: #113149;
      box-shadow: 0 15px 40px rgba(21, 37, 72, 0.24);
    }

    &:active {
      background-color: #091b28;
    }
  }

  &--white {
    background-color: white;
    box-shadow: $base-box-shadow;
    color: $theme-color-primary;

    &:hover {
      background-color: $theme-color-primary;
      box-shadow: 0 15px 40px rgba(21, 37, 72, 0.24);
    }

    &:active {
      background-color: #06a49a;
    }
  }

  &--outline {
    background-color: transparent;
    color: $color-black-blue;
    border: 2px solid $theme-color-primary;
    position: relative;
    box-shadow: none;

    &:hover {
      color: $color-black-blue;
      background-color: white;
      box-shadow: none;
      border: 2px solid #02c3ac;
    }

    &[disabled] {
      box-shadow: none;
      border: 2px solid rgba(#405262, 0.6);
    }
  }
}
