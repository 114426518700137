@import '../../../theme/base/variables';

/**
 *  Link styles
 */
a {
  color: $base-link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $base-link-hover-color;
    text-decoration: underline;
  }

  &:active {
    color: $base-link-color;
  }
}

.link {
  // optional
}
