@import '../../../theme/base/variables';

/**
 *  List styles
 */
ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ul {
  li {
    padding-left: 2rem;
    margin: 0 0 1rem;
    background: $ico_list-arrow left 7px no-repeat;
  }
}

.ol {
  counter-reset: item;

  > li {
    counter-increment: item;
    padding-left: 2rem;
    margin: 0 0 1rem;
    position: relative;

    &::before {
      content: counter(item);
      position: absolute;
      left: 0;
      top: 0;
      font-weight: bold;
      font-size: 24px;
      line-height: 1;
      color: #05ada3;
    }
  }
}

ul {
  @extend .ul;
}

ol {
  @extend .ol;
}

.list {
  @media (min-width: $tablet) {
    max-width: 800px;
  }

  @media (min-width: $desktop) {
    margin: 1rem 0;
  }

  &--check {
    li {
      padding-left: 3.5rem;
      position: relative;
      background: none;

      &::before {
        content: '';
        width: 40px;
        height: 36px;
        position: absolute;
        left: 0;
        top: 0;
        background: $ico_check no-repeat;
        background-size: cover;
      }

      .h3,
      h3 {
        margin-bottom: 0.5rem;
        line-height: 1.4;
        display: inline-block;
      }
    }
  }

  &--check.list--check-x {
    li::before {
      background-image: $ico_check-x;
    }
  }

  &-icons {
    @media (min-width: $desktop) {
      margin-top: 1.5rem;
    }

    &__item {
      background-position: left 0;
      background-repeat: no-repeat;
      min-height: 56px;
      font-size: 20px;
      line-height: 1.4;
      color: $color-gray-text;
      margin: 1rem 0;
      display: inline-block;
      width: 100%;
      background-image: none;
      padding-left: 4.5rem;
      background-size: 3.5rem auto;

      @media (min-width: $desktop) {
        background-size: 4.5rem auto;
        margin: 0;
        padding-left: 6.5rem;
      }

      h2,
      .h2,
      h3,
      .h3,
      strong {
        font-size: 20px;
        line-height: 1.3;
        margin: 1rem 0 0.5rem;
        color: $color-black-blue;
        display: inline-block;

        @media (min-width: $desktop) {
          font-size: 1.5rem;
          line-height: 1.42;
        }
      }

      p {
        font-size: 1rem;
        line-height: 1.5;
        color: $color-gray-text;

        a {
          color: $theme-color-primary;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &--stars {
    li {
      padding-left: 3rem;
      background-image: $ico_star;
      background-position: left 0;
    }

    .list__heading {
      font-size: 1.5rem;
      line-height: 1.4;
      margin-bottom: 0.5rem;
      display: block;
    }
  }

  &--numbers {
    counter-reset: list;

    > li {
      counter-increment: list;
      padding-left: 3.5rem;
      position: relative;
      background: none;

      @media (min-width: $tablet) {
        padding-left: 4.5rem;
      }

      &::before {
        content: counter(list);
        color: white;
        font-size: 1rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 39px;
        height: 35px;
        position: absolute;
        left: 0;
        top: -5px;
        background: $ico_list-numbers center center/contain no-repeat;

        @media (min-width: $tablet) {
          width: 48px;
          height: 48px;
          font-size: 1.5rem;
        }
      }

      strong,
      h3,
      .h3 {
        margin-bottom: 0.25rem;

        @media (min-width: $desktop) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  &--hiw-v_2 {
    position: relative;
    max-width: none;
    margin: 2rem 0;

    @media (min-width: $tablet) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 3rem 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 30px;
      bottom: 30px;
      left: 30px;
      width: 2px;
      background-color: $color-black-blue;
      z-index: 0;

      @media (min-width: $tablet) {
        top: 56px;
        bottom: auto;
        right: 130px;
        left: 150px;
        width: auto;
        height: 2px;
      }
    }

    &.load-bg {
      li {
        &:nth-of-type(1) {
          &::before {
            background-image: $ico_list-hiw-1;
          }
        }

        &:nth-of-type(2)::before {
          background-image: $ico_list-hiw-2;
        }

        &:nth-of-type(3)::before {
          background-image: $ico_list-hiw-3;
        }

        &:nth-of-type(4)::before {
          background-image: $ico_list-hiw-4;
        }
      }
    }

    li {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0;
      margin: 0;
      background: none;
      z-index: 1;

      @media (min-width: $tablet) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 180px;
      }

      + li {
        margin-top: 2.5rem;

        @media (min-width: $tablet) {
          margin-top: 0;
        }
      }

      &::before {
        content: '';
        position: relative;
        margin-right: 12px;
        width: 68px;
        min-width: 68px;
        height: 64px;
        background: #fff center center no-repeat;
        box-shadow: 0 -10px 0 white, 0 10px 0 white;

        @media (min-width: $tablet) {
          margin-right: 0;
          margin-bottom: 18px;
          width: 117px;
          min-width: 117px;
          height: 100px;
          box-shadow: none;
        }
      }

      &:nth-of-type(1) {
        &::before {
          background-size: 68px 55px;
          left: -10px;

          @media (min-width: $tablet) {
            background-size: 117px 96px;
            left: -15px;
            top: 5px;
          }
        }
      }

      &:nth-of-type(2)::before {
        background-size: 65px 59px;

        @media (min-width: $tablet) {
          background-size: 108px 100px;
          box-shadow: -12px 0 0 white;
          top: 10px;
        }
      }

      &:nth-of-type(3)::before {
        background-size: 51px 64px;

        @media (min-width: $tablet) {
          background-size: 79px 98px;
        }
      }

      &:nth-of-type(4)::before {
        background-size: 58px 61px;

        @media (min-width: $tablet) {
          background-size: 90px 96px;
        }
      }

      p {
        margin: 0;
      }

      span {
        display: block;
        font-size: 14px;
        opacity: 0.75;
      }
    }
  }

  &--steps {
    li {
      padding-left: 5rem;
      position: relative;
      background: none;
      color: $color-black-blue;

      @media (min-width: $tablet) {
        padding-left: 4.5rem;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -2px;
        background-repeat: no-repeat;
        width: 64px;
        height: 60px;
        background-size: cover;
      }

      &:nth-of-type(1)::before {
        background-image: $ico_step-1;
        width: 59px;
      }

      &:nth-of-type(2)::before {
        background-image: $ico_step-2;
      }

      &:nth-of-type(3)::before {
        background-image: $ico_step-3;
      }

      &:nth-of-type(4)::before {
        background-image: $ico_step-4;
        width: 55px;
      }

      &:nth-of-type(5)::before {
        background-image: $ico_step-5;
        width: 55px;
      }

      &:nth-of-type(6)::before {
        background-image: $ico_step-6;
        width: 55px;
      }
    }
  }

  &--columns-2,
  &--columns-3,
  &--columns-4 {
    @media (min-width: $tablet) {
      display: grid;
      justify-items: stretch;
      column-gap: 1.5rem;
      row-gap: 1.5rem;
      width: 100%;
      max-width: 100%;
    }

    > * {
      @media (min-width: $tablet) {
        margin-bottom: 0;
      }
    }
  }

  &--columns-2 {
    @media (min-width: $tablet) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: $desktop) {
      max-width: 1024px;
    }
  }

  &--columns-3 {
    @media (min-width: $tablet) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: $desktop) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &--max-content {
      @media (min-width: $tablet) {
        grid-template-columns: repeat(2, max-content);
      }

      @media (min-width: $desktop) {
        grid-template-columns: repeat(3, max-content);
      }
    }
  }

  &--columns-4 {
    @media (min-width: $mobile) {
      display: grid;
      justify-items: stretch;
      column-gap: 1.5rem;
      row-gap: 1.5rem;
      width: 100%;
      max-width: 100%;
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: $tablet) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: $desktop) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
