.section-socials {
  background-color: $color-black-blue;

  @media (min-width: $desktop) {
    padding: 3rem 0;
  }

  .container {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $tablet) {
      align-items: center;
    }
  }

  a {
    display: inline-block;
  }

  &__apps,
  &__links {
    width: 100%;
    text-align: center;
    height: 50px;

    @media (min-width: $mobile-small) {
      max-width: 50%;
    }

    @media (min-width: $tablet) {
      max-width: none;
      width: auto;
    }
  }

  &__apps {
    @media (min-width: $mobile-small) {
      text-align: left;
    }

    &__item {
      width: 164px;
      height: 50px;
      margin-bottom: 1rem;
      border-radius: $base-border-radius;

      @media (min-width: $tablet) {
        margin: 0;
      }

      &:hover {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.13) inset;
      }
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (min-width: $mobile-small) {
      text-align: right;
    }

    @media (min-width: $tablet) {
      margin: auto;
    }

    &__item {
      opacity: 0.3;
      transition: opacity 0.2s;
      margin: 0 0.5rem;

      @media (min-width: $mobile-small) {
        margin: 0 0 0 1rem;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &__badges {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: $tablet) {
      width: auto;
      margin: 0 0 0 2rem;
    }

    a {
      margin: 0 0.5rem;

      @media (min-width: $tablet) {
        margin: 0 0 0 1rem;
      }
    }
  }
}
