// Features
$ico_plagiarism-free: url('/images/ico_plagiarism-free.svg');
$ico_superwriters: url('/images/ico_superwriters.svg');
$ico_freerevisions: url('/images/ico_freerevisions.svg');
$ico_short-deadlines: url('/images/ico_short-deadlines.svg');
$ico_flexible-pricing: url('/images/ico_flexible-pricing.svg');
$ico_custom-formatting: url('/images/ico_custom-formatting.svg');
$ico_disciplines: url('/images/ico_disciplines.svg');
$ico_easy-order-tracking: url('/images/ico_easy-order-tracking.svg');
$ico_free-features: url('/images/ico_free-features.svg');
$ico_quality: url('/images/ico_quality.svg');
$ico_deadlines: url('/images/ico_deadlines.svg');
$ico_discipline: url('/images/ico_discipline.svg');
$ico_moneyback: url('/images/ico_moneyback.svg');
$ico_security: url('/images/ico_security.svg');
$ico_narrow: url('/images/ico_narrow.svg');
$ico_complex: url('/images/ico_complex.svg');
$ico_testimonials: url('/images/ico_testimonials.svg');
$ico_samples: url('/images/ico_samples.svg');
$ico_tools: url('/images/ico_tools.svg');
$ico_expert: url('/images/ico_expert.svg');
$ico_secure: url('/images/ico_secure.svg');
$ico_revisions_refunds: url('/images/ico_revisions_refunds.svg');
$ico_confidentiality: url('/images/ico_confidentiality.svg');
$ico_secrets: url('/images/ico_secrets.svg');
$ico_phone-contacts: url('/images/ico_phone-contacts.svg');
$ico_location: url('/images/ico_location.svg');
$ico_chat: url('/images/ico_chat.svg');
$ico_email: url('/images/ico_email.svg');
$ico_fast-delivery: url('/images/ico_fast-delivery.svg');
$ico_flexible_prices: url('/images/ico_flexible_prices.svg');
$ico_writer: url('/images/ico_writer.svg');
$ico_writer-award: url('/images/ico_writer-award.svg');
$ico_assistance: url('/images/ico_assistance.svg');
$ico_flexibility: url('/images/ico_flexibility.svg');
$ico_paper-star: url('/images/ico_paper-star.svg');
$ico_attention: url('/images/ico_attention.svg');
$ico_step-1: url('/images/ico_step-1.svg');
$ico_step-2: url('/images/ico_step-2.svg');
$ico_step-3: url('/images/ico_step-3.svg');
$ico_step-4: url('/images/ico_step-4.svg');
$ico_step-5: url('/images/ico_step-5.svg');
$ico_step-6: url('/images/ico_step-6.svg');

// Money back guarantee
$ico_cancelation-money-back: url('/images/money-back-guarantee/ico_cancelation.svg');
$ico_lateness-money-back: url('/images/money-back-guarantee/ico_lateness.svg');
$ico_refund_options-money-back: url('/images/money-back-guarantee/ico_refund_options.svg');
$ico_other-money-back: url('/images/money-back-guarantee/ico_other.svg');
$ico_quality-money-back: url('/images/money-back-guarantee/ico_quality-money-back.svg');

// Pricing factors
$ico_level: url('/images/ico_level.svg');
$ico_pages: url('/images/ico_pages.svg');
$ico_urgency: url('/images/ico_urgency.svg');
$ico_type-of-paper: url('/images/ico_type-of-paper.svg');

// FAQ page
$ico_faq-all: url('/images/faq/ico_all.svg');
$ico_faq-info: url('/images/faq/ico_info.svg');
$ico_faq-order: url('/images/faq/ico_order.svg');
$ico_faq-options: url('/images/faq/ico_options.svg');
$ico_faq-placed_order: url('/images/faq/ico_placed_order.svg');
$ico_faq-wrong: url('/images/faq/ico_wrong.svg');
$ico_faq-wrong: url('/images/faq/ico_wrong.svg');

// Our experts - steps
$img_writers-step-1: url('/images/img_writers-step-1.svg');
$img_writers-step-2: url('/images/img_writers-step-2.svg');
$img_writers-step-3: url('/images/img_writers-step-3.svg');

// Special offer page
$img_special-offer-form: url('/images/img_special-offer-form.svg');
$ico_checkbox: url('/images/ico_checkbox.svg');
$img_sp-offer-text-bg: url('/images/img_sp-offer-text-bg.svg');

// SEO page
$ico_seo-title-bubble-1: url('/images/ico_seo-title-bubble-1.svg');
$ico_seo-title-bubble-2: url('/images/ico_seo-title-bubble-2.svg');
$ico_seo-title-bubble-3: url('/images/ico_seo-title-bubble-3.svg');

$ico_list-arrow: url('/images/ico_list-arrow.svg');
$ico_hiw-nav: url('/images/ico_hiw-nav.svg');
$ico_slider-arrow: url('/images/ico_slider-arrow.svg');
$ico_arrow-left-active: url('/images/ico_slider-arrow.svg');
$ico_arrow-left: url('/images/ico_arrow-left.svg');
$ico_truspilot: url('/images/ico_truspilot.svg');
$img_recently-papers: url('/images/img_recently-papers.svg');
$ico_check: url('/images/ico_check.svg');
$ico_list-numbers: url('/images/ico_list-numbers.svg');
$ico_paper: url('/images/ico_paper.svg');
$img_make-paper-better: url('/images/img_make-paper-better.svg');
$img_calculator: url('/images/img_calculator.svg');
$img_seo-bg: url('/images/img_seo-bg.svg');
$img_seo-bg2: url('/images/img_seo-bg2.svg');
$ico_manage-orders: url('/images/ico_manage-orders.svg');
$ico_arrow-right: url('/images/ico_arrow-right.svg');
$ico_close: url('/images/ico_close.svg');
$ico_24-7: url('/images/ico_24-7.png');
$ico_24-7-webp: url('/images/ico_24-7.webp');
$ico_phone: url('/images/ico_phone.svg');
$ico_user: url('/images/ico_user.svg');
$img_covered-text: url('/images/img_covered-text.svg');
$ico_facebook-btn: url('/images/ico_facebook-btn.svg');
$ico_whatsapp-btn: url('/images/ico_whatsapp-btn.svg');
$ico_livechat-btn: url('/images/ico_livechat-btn.svg');
$ico_manage-orders-arrow: url('/images/ico_manage-orders-arrow.svg');
$ico_star: url('/images/ico_star.svg');
$ico_check-x: url('/images/ico_check-x.svg');
$ico_play: url('/images/ico_play.svg');
$ico_pause: url('/images/ico_pause.svg');
$ico_hiw-slide-arrow_disabled: url('/images/ico_hiw-slide-arrow_disabled.svg');
$ico_hiw-slide-arrow: url('/images/ico_hiw-slide-arrow.svg');
$ico_list-hiw-1: url('/images/ico_list_hiw_1.svg');
$ico_list-hiw-2: url('/images/ico_list_hiw_2.svg');
$ico_list-hiw-3: url('/images/ico_list_hiw_3.svg');
$ico_list-hiw-4: url('/images/ico_list_hiw_4.svg');
$img_top-section_books: url('/images/img_top-section_books.svg');
$ico_whatsapp-green: url('/images/ico_whatsapp.svg');
