@import '../../../theme/base/variables';

/**
 *  Layout styles
 */

.main-wrapper {
  padding-top: 1px;
}

.container {
  width: 100%;
  padding-left: $section-padding-mobile;
  padding-right: $section-padding-mobile;

  @media (min-width: $desktop) {
    padding-left: $section-padding-desktop;
    padding-right: $section-padding-desktop;
  }

  @media (min-width: $desktop) {
    max-width: $container-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.overflow {
  overflow: hidden;
}

.hidden-info::after {
  content: attr(data-title);
}

.section {
  padding: 2.5rem 0;
  position: relative;

  @media (min-width: $desktop) {
    padding: 7.5rem 0;
  }

  &.section--no-padding {
    padding: 0;
  }

  &.section--small-padding {
    padding: 1.5rem 0;

    @media (min-width: $desktop) {
      padding: 2.5rem 0;
    }
  }

  &.section--bottom-padding {
    padding-top: 0;

    @media (min-width: $desktop) {
      padding-top: 0;
    }
  }

  &.section--top-padding {
    padding-bottom: 0;

    @media (min-width: $desktop) {
      padding-bottom: 0;
    }
  }

  &__heading {
    font-size: $h2-mobile-font-size;
    line-height: $h2-line-height;
    margin-bottom: 1rem;
    display: inline-block;
    width: 100%;
    letter-spacing: 0.1785px;
    position: relative;

    @media (min-width: $desktop) {
      margin-bottom: 1.5rem;
      font-size: $h2-desktop-font-size;
    }

    &::before {
      content: '';
      width: 40px;
      height: 40px;
      position: absolute;
      top: -45px;
      left: 0;
      background: center center no-repeat;
      background-size: contain;

      @media (min-width: $tablet) {
        width: 80px;
        height: 80px;
        top: -4px;
        left: -100px;
      }
    }

    &--bubble1 {
      &::before {
        background-image: $ico_seo-title-bubble-1;
      }
    }

    &--bubble2 {
      &::before {
        background-image: $ico_seo-title-bubble-2;
      }
    }

    &--bubble3 {
      &::before {
        background-image: $ico_seo-title-bubble-3;

        @media (min-width: $tablet) {
          top: 0;
        }
      }
    }

    span {
      color: $theme-color-primary;
      font-family: $font-secondary;
      font-size: 2.5rem;
      line-height: 1.1;
      font-weight: 400;
      letter-spacing: 0.2px;
      white-space: nowrap;

      @media (min-width: $desktop) {
        font-size: 76px;
        line-height: 0.9;
      }
    }
  }

  &__description {
    @media (min-width: $tablet) {
      max-width: 670px;
    }

    @media (min-width: $desktop) {
      font-size: 1.25rem;
    }

    br {
      display: none;

      @media (min-width: $tablet) {
        display: inline;
      }
    }
  }

  &--bg-blue {
    background-color: $color-gray-bg;
  }

  &--bg-blue-mobile {
    @media (min-width: $tablet) {
      background-color: $color-gray-bg;
    }
  }

  &--bg-white {
    background-color: white;
  }
}
